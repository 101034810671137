import React from "react";
import Layout from "../components/layout";
import Projects from "../components/projects/Projects";
import SEO from "../components/seo";

const IndexPage = () => (
  <Layout>
    <SEO title="Portfolio | Alfredo Riveroll" />
    <div className="container">
      <h1 className="t2 ComponentSeparation">What I have done</h1>
      <div className="ComponentSeparation">
        <p>I have done more than 25 websites, here are the newest ones.</p>
        <p>
          Currently working on two projects using React JS(CRA & Gatsby JS) and
          one more using PHP Wordpress.
        </p>
      </div>

      <Projects />
    </div>
  </Layout>
);

export default IndexPage;
