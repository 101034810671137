import React from "react";
import style from "./visit.module.scss";

const Visit = (props) => (
    <a href={props.link} className={`${style.Visit}`} target="_blank" rel="noopener noreferrer">
        VISIT SITE
    </a>

)

export default Visit