import React, { Fragment } from "react";
import style from "./project.module.scss";
import Visit from "../visit/Visit";
import shortid from "shortid";


const Project = (props) => (
    <div className={`${style.description} grid-u-lg-1-2`}>

        <div className={style.Project}>
            <p className={style.Project_title}>Technologies:</p>
            <ul>
                {props.info.tech.map((data, index) => {
                    return (
                        <Fragment key={shortid.generate()}>
                            <li>{data}</li>
                        </Fragment>

                    )
                })}
            </ul>

        </div>
        {props.info.extra && <div className="small" style={{
            color: '#929292'
        }}> {props.info.extra}</div>}

        {props.info.link && <div><Visit link={props.info.link} /></div>}

    </div >
)

export default Project


// <p className={style.Project_title}>What I did:</p>
//             <ul>
//                 {props.info.did.map((data, index) => {
//                     return (
//                         <Fragment key={shortid.generate()}>
//                             <li>{data}</li>
//                         </Fragment>

//                     )
//                 })}
//             </ul>

// <div className={style.Project}>
//             <div className={style.Project_type}>
//                 <p className={style.Project_title}>Type of Project:</p>
//                 <p style={{
//                     marginLeft: '.5rem'
//                 }}>{props.info.type}</p>
//             </div>

//         </div>