import React from 'react';
import style from './projects.module.scss';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import Project from '../project/Project';
// import sp1 from "../../images/sp1.jpg"; import ireact from
// "../../images/react.svg";

const mipon = {
  type: 'Personal Project',
  did: ['Design', 'Code', 'SJSADÑLKAKL', 'SJSADÑLKAKL', 'SJSADÑLKAKL'],
  tech: [
    'HTML',
    'CSS/SCSS',
    'PHP Wordpress',
    'Gulp',
    ,
    'BitBucket',
    'Cloudflare',
    'AWS (S3 & Route53)'
  ],
  link: 'https://mipon.org'
};

const portal = {
  type: 'Work (FreeWill)',
  tech: [
    'HTML',
    'CSS/SCSS',
    'PHP Wordpress',
    'Gulp',
    ,
    'Github',
    'AWS (S3, EC2, CloudFront & Route53)'
  ],
  extra: '*Only the employees of FreeWill can access to this website.'
};

const mrwash = {
  type: 'Freelance',
  tech: [
    'HTML/Pug',
    'CSS/SCSS',
    'Prepros',
    'Github',
    'AWS (S3, CloudFront & Route53)'
  ],
  link: 'https://mrwashmx.com'
};

const greenhall = {
  type: 'Personal Project',
  tech: [
    'HTML',
    'CSS/SCSS',
    'PHP Wordpress',
    'Gulp',
    'Github',
    'Cloudflare',
    'AWS (Route53)'
  ],
  link: 'https://greenhall.org'
};

const portfolio = {
  tech: [
    'React JS (Gatsby)',
    'GraphQL',
    'CSS Modules with SCSS',
    'Github',
    'Netlify',
    'AWS (Route53)'
  ],
  link: 'https://ribaus.com'
};

const ljl = {
  tech: [
    'React (Next JS)',
    'Styled Components',
    'Dynamo DB',
    'GraphQL',
    'Gitlab',
    'AWS (S3, CloudFront & Route53)'
  ],
  extra: '*The project has not been launched yet.'
};

const cgpc = {
  tech: [
    'React (Create React App)',
    'React Spring (Animations)',
    'Styled Components',
    'Firebase',
    'Gitlab',
    'AWS (S3, CloudFront & Route53)'
  ],
  extra: '*Only the employees of Playbrain can access to this application.'
};

const dekki = {
  tech: [
    'React (Create React App)',
    'Styled Components',
    'Dynamo DB',
    'GraphQL',
    'Gitlab',
    'AWS (S3, CloudFront & Route53)'
  ],
  link: 'https://dekki.com/en/games/legends-of-runeterra/deck-builder'
};

const futaroku = {
  tech: [
    'React (Gatsby JS)',
    'Styled Components',
    'Gitlab',
    'AWS (S3, CloudFront & Route53)'
  ],
  link: 'https://futaroku.gg/'
};

const playbrainCms = {
  tech: [
    'React (Create React App)',
    'Styled Components',
    'Dynamo DB',
    'GraphQL',
    'Gitlab',
    'AWS (S3, CloudFront & Route53)'
  ],
  extra: '*Only the employees of Playbrain can access to this website.'
};

const playbrainRenewal = {
  tech: [
    'React (Gatsby JS)',
    'Styled Components',
    'GraphQL',
    'Gitlab',
    'AWS (S3, CloudFront & Route53)'
  ],
  extra: '*The project has not been launched yet.'
};

const Projects = props => {
  const data = useStaticQuery(graphql`
    fragment servicesImage on File {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    query {
      futaroku: file(relativePath: { eq: "futaroku.jpg" }) {
        ...servicesImage
      }
      mipon: file(relativePath: { eq: "p1.jpg" }) {
        ...servicesImage
      }
      dekki: file(relativePath: { eq: "dekki.jpg" }) {
        ...servicesImage
      }
      cgpc: file(relativePath: { eq: "cgpc.jpg" }) {
        ...servicesImage
      }
      ljl: file(relativePath: { eq: "ljl.jpg" }) {
        ...servicesImage
      }
      portal: file(relativePath: { eq: "p2.jpg" }) {
        ...servicesImage
      }
      mrwash: file(relativePath: { eq: "p3.jpg" }) {
        ...servicesImage
      }
      greenhall: file(relativePath: { eq: "p6.jpg" }) {
        ...servicesImage
      }
      portfolio: file(relativePath: { eq: "p8.jpg" }) {
        ...servicesImage
      }
      playbrainCms: file(relativePath: { eq: "playbrain-cms.jpg" }) {
        ...servicesImage
      }
      playbrainRenewal: file(relativePath: { eq: "playbrain-renewal.jpg" }) {
        ...servicesImage
      }
    }
  `);
  return (
    <ul className={style.Projects}>
      <li className={style.Projects_single}>
        <div className="grid-u-lg-1-2">
          <Img
            fluid={data.mipon.childImageSharp.fluid}
            className="ResponsiveImg_img"
          />
        </div>
        <Project info={mipon} />
      </li>
      <li className={style.Projects_single}>
        <div className="grid-u-lg-1-2">
          <Img
            fluid={data.dekki.childImageSharp.fluid}
            className="ResponsiveImg_img"
          />
        </div>
        <Project info={dekki} />
      </li>
      <li className={style.Projects_single}>
        <div className="grid-u-lg-1-2">
          <Img
            fluid={data.cgpc.childImageSharp.fluid}
            className="ResponsiveImg_img"
          />
        </div>
        <Project info={cgpc} />
      </li>
      <li className={style.Projects_single}>
        <div className="grid-u-lg-1-2">
          <Img
            fluid={data.playbrainCms.childImageSharp.fluid}
            className="ResponsiveImg_img"
          />
        </div>
        <Project info={playbrainCms} />
      </li>
      <li className={style.Projects_single}>
        <div className="grid-u-lg-1-2">
          <Img
            fluid={data.ljl.childImageSharp.fluid}
            className="ResponsiveImg_img"
          />
        </div>
        <Project info={ljl} />
      </li>

      <li className={style.Projects_single}>
        <div className="grid-u-lg-1-2">
          <Img
            fluid={data.futaroku.childImageSharp.fluid}
            className="ResponsiveImg_img"
          />
        </div>
        <Project info={futaroku} />
      </li>
      <li className={style.Projects_single}>
        <div className="grid-u-lg-1-2">
          <Img
            fluid={data.playbrainRenewal.childImageSharp.fluid}
            className="ResponsiveImg_img"
          />
        </div>
        <Project info={playbrainRenewal} />
      </li>
      <li className={style.Projects_single}>
        <div className="grid-u-lg-1-2">
          <Img
            fluid={data.portal.childImageSharp.fluid}
            className="ResponsiveImg_img"
          />
        </div>
        <Project info={portal} />
      </li>
      <li className={style.Projects_single}>
        <div className="grid-u-lg-1-2">
          <Img
            fluid={data.mrwash.childImageSharp.fluid}
            className="ResponsiveImg_img"
          />
        </div>
        <Project info={mrwash} />
      </li>
      <li className={style.Projects_single}>
        <div className="grid-u-lg-1-2">
          <Img
            fluid={data.portfolio.childImageSharp.fluid}
            className="ResponsiveImg_img"
          />
        </div>
        <Project info={portfolio} />
      </li>
      <li className={style.Projects_single}>
        <div className="grid-u-lg-1-2">
          <Img
            fluid={data.greenhall.childImageSharp.fluid}
            className="ResponsiveImg_img"
          />
        </div>
        <Project info={greenhall} />
      </li>
    </ul>
  );
};

export default Projects;
